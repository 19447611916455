<template>
    <div class="card">
        <div v-if="!submitted">

            <div v-if="student && checkStudentDisabled(student)">
                <h5>ATTENZIONE</h5>
                <p>
                    Non hai abbastanza ore per prenotare questa guida.<br/>
                    Tra ore consumate e prenotate ti rimangono solo <b>{{ convertStudentSecondsToHour(student) }} ore</b> di guida {{ (isVirtual) ? 'al simulatore' : 'reale' }}.
                    <br><br>
                    <router-link to="available-times-student" class="btn btn-outline-primary">Controlla il tuo monte ore</router-link>
                </p>
            </div>

            <div v-else>
                <h5>{{ formatDate(booking.dateStart) }}</h5>
                <b-form @submit.stop.prevent="saveBooking">

                    <b-form-group id="studentId" label="Studente" label-for="studentId" description="" v-if="!student">
                        <b-form-select v-model="booking.studentId" :state="validateState('studentId')">
                            <option value="0">- Scegli uno studente -</option>
                            <option v-for="student in students" v-bind:value="student.id" :key="student.id"
                                    :disabled="checkStudentDisabled(student)">
                                {{ student.firstname }} {{ student.lastname }} ({{ convertStudentSecondsToHour(student) }}/h)
                            </option>
                        </b-form-select>
                    </b-form-group>

                    <b-form-group id="instructorId" label="Istruttore" label-for="instructorId" description=""
                                  v-if="!isVirtual && instructors.length > 0">
                        <b-form-select v-model="booking.instructorId" :state="validateState('instructorId')"
                                       :disabled="disable_instructors">
                            <option value="0">- Scegli un istruttore -</option>
                            <option v-for="instructor in instructors" v-bind:value="instructor.id" :key="instructor.id">
                                {{ instructor.firstname }} {{ instructor.lastname }}
                            </option>
                        </b-form-select>
                    </b-form-group>

                    <p v-if="instructors.length == 0 && !this.isVirtual"><b-icon icon="exclamation-diamond"/> Non ci sono istruttori disponibili in questa fascia oraria</p>

                    <b-form-group id="vehicleId" label="Mezzo" label-for="vehicleId" description="" v-if="vehicles.length > 0">
                        <b-form-select v-model="booking.vehicleId" :state="validateState('vehicleId')" :disabled="!canChangeVehicle">
                            <option value="0">- Scegli un veicolo -</option>
                            <template v-for="vehicle in vehicles">
                                <option v-if="checkVehicleEnabled(vehicle)" v-bind:value="vehicle.id" :key="vehicle.id">
                                    {{ vehicle.name }}
                                </option>
                                <option v-else v-bind:value="vehicle.id" :key="vehicle.id" disabled>
                                    {{ vehicle.name }} (Non disponibile)
                                </option>
                            </template>
                        </b-form-select>
                    </b-form-group>

                    <p v-if="vehicles.length == 0"><b-icon icon="exclamation-diamond"/> Non ci sono mezzi disponibili in questa fascia oraria</p>


                </b-form>

                <div class="action-bar row">
                    <div class="col-sm-6">
                        <button class="btn btn-success " @click="saveBooking" :disabled="this.$store.state.loading">
                            <b-icon icon="file-earmark-check"/>
                            Salva
                        </button>

                    </div>
                    <div class="col-sm-6 text-right">
                        <button class="btn btn-link " @click="closeEditor" :disabled="this.$store.state.loading">
                            <b-icon icon="x"/>
                            Annulla
                        </button>
                    </div>
                </div>
                <div>
                    <br>
                    <b-alert variant="success" :show="message != ''" fade>
                        <b-icon icon="hand-thumbs-up"/>
                        {{ message }}
                    </b-alert>
                    <b-alert variant="danger" :show="errorMsg != ''">{{ errorMsg }}</b-alert>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
import BookingDataService from "./BookingDataService";
import InstructorAvailabilityDataService from "@/components/instructor_availability/InstructorAvailabilityDataService";
import {required} from 'vuelidate/lib/validators'
import StudentDataService from "@/components/student/StudentDataService";
import InstructorDataService from "@/components/instructor/InstructorDataService";
import VehicleDataService from "@/components/vehicle/VehicleDataService";
import moment from 'moment';
import {store} from "@/components/store";

export default {
    name: "booking-create",
    data() {
        return {
            disable_instructors: false,
            limit_seconds_disable_student: 600,
            difference_date_booking: 0,
            bookedSeconds: 0,
            vehicles: [],
            canChangeVehicle: true,
            students: [],
            student: null,
            instructors: [],
            submitted: false,
            message: '',
            errorMsg: '',
        };
    },
    props: ["booking", "isVirtual", "studentId"],
    validations() {
        return {
            booking: this.rules
        }
    },
    methods: {
        checkVehicleEnabled(vehicle) {
            let enabled = true;
            if (vehicle.bookings != null && vehicle.bookings.length > 0) {
                let ds = new moment(this.booking.dateStart);
                let de = new moment(this.booking.dateEnd);
                enabled = vehicle.bookings.every(b => new moment(b.dateEnd) <= ds || new moment(b.dateStart) >= de);
            }
            if (vehicle.unavailabilityDateStart != null) {
                let ds = new moment(this.booking.dateStart);
                let de = new moment(this.booking.dateEnd);
                if (vehicle.unavailabilityDateEnd == null) enabled = ds.isBefore(vehicle.unavailabilityDateStart);
                else enabled = ds.isBefore(vehicle.unavailabilityDateStart, 'days') || ds.isAfter(vehicle.unavailabilityDateEnd, 'days');
            }
            return enabled;
        },
        checkStudentDisabled(student) {
            let disabled = true;
            if (student.availableTime != null) {
                if (this.isVirtual) disabled = student.availableTime.secondsLeftSimulator < this.limit_seconds_disable_student || student.availableTime.secondsLeftSimulator < this.difference_date_booking;
                else disabled = student.availableTime.secondsLeftPractice < this.limit_seconds_disable_student || student.availableTime.secondsLeftPractice < this.difference_date_booking;
            }
            return disabled;
        },
        convertStudentSecondsToHour(student) {
            let hours = 0;
            if (student.availableTime != null) {
                if (this.isVirtual) hours = moment.duration(student.availableTime.secondsLeftSimulator, 'seconds').asHours().toFixed(2);
                else hours = moment.duration(student.availableTime.secondsLeftPractice, 'seconds').asHours().toFixed(2);
            }
            return hours;
        },

        convertSecondsToHour(seconds) {
            let hours = 0;
            if (seconds != null) {
                hours = moment.duration(seconds, 'seconds').asHours().toFixed(2);
            }
            return hours;
        },

        validateState(name) {
            const {$dirty, $error} = this.$v.booking[name];
            return $dirty ? !$error : null;
        },
        saveBooking() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.submitted = false;
            } else {
                if (this.isVirtual) {
                    this.booking.isVirtual = true;
                    this.booking.instructorId = 0;
                }
                BookingDataService.create(this.booking)
                    .then(response => {
                        this.booking.id = response.data.id;
                        this.submitted = true;

                        this.booking.vehicleId = 0;
                        this.booking.studentId = 0;
                        this.$emit('created-booking');
                    })
                    .catch(e => {
                        this.errorMsg = 'Non hai compilato tutti i campi';
                        console.log(e);
                    });
            }
        },

        closeEditor() {
            this.booking.instructorId = 0;
            this.booking.vehicleId = 0;
            this.booking.studentId = 0;
            this.$emit('closed-editor');
        },
        formatDate(datetime) {
            moment.locale('it');
            let formattedDate = moment(datetime).format('LLLL');
            return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
        },
        getStudents(id) {
            if (id != null && id > 0) {
                StudentDataService.get(id)
                    .then(response => {
                        this.students = new Array(response.data);
                        if (this.students.length > 0) {
                            this.students.forEach(student => {
                                const startDate = moment().format('YYYY-MM-DD');
                                if (this.isVirtual) {
                                    if (student.availableTime != null && student.availableTime.secondsLeftSimulator > 0) {
                                        BookingDataService.getCountSecondsByStudentAndDate(student.id, startDate, true).then(r => {
                                            student.availableTime.secondsLeftSimulator -= r.data.seconds;
                                        });
                                    }
                                }
                                else {
                                    if (student.availableTime != null && student.availableTime.secondsLeftPractice > 0) {
                                        BookingDataService.getCountSecondsByStudentAndDate(student.id, startDate, false).then(r => {
                                            student.availableTime.secondsLeftPractice -= r.data.seconds;
                                        });
                                    }
                                }
                            });
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    });
            } else {
                StudentDataService.getAll()
                    .then(response => {
                        this.students = response.data;
                        if (this.students.length > 0) {
                            this.students.forEach(student => {
                                const startDate = moment().format('YYYY-MM-DD');
                                if (this.isVirtual) {
                                    if (student.availableTime != null && student.availableTime.secondsLeftSimulator > 0) {
                                        BookingDataService.getCountSecondsByStudentAndDate(student.id, startDate, true).then(r => {
                                            //this.bookedSeconds = r.data.seconds;
                                            student.availableTime.secondsLeftSimulator -= r.data.seconds;
                                        });
                                    }
                                } else {
                                    if (student.availableTime != null && student.availableTime.secondsLeftPractice > 0) {
                                        BookingDataService.getCountSecondsByStudentAndDate(student.id, startDate, false).then(r => {
                                            //this.bookedSeconds = r.data.seconds;
                                            student.availableTime.secondsLeftPractice -= r.data.seconds;
                                        });
                                    }
                                }


                            });
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    });
            }

        }
    },
    computed: {
        rules () {
            return this.isVirtual
                ? { studentId: { required }, vehicleId: { required } }
                : { studentId: { required }, vehicleId: { required }, instructorId: { required } }
        }
    },
    mounted() {
        this.disable_instructors = this.booking.instructorId > 0;
        if (this.studentId > 0) {
            StudentDataService.get(this.studentId)
                .then(response => {
                    this.student = response.data;
                    const startDate = moment().format('YYYY-MM-DD');
                    if (this.isVirtual) {
                        if (this.student.availableTime != null && this.student.availableTime.secondsLeftSimulator > 0) {
                            BookingDataService.getCountSecondsByStudentAndDate(this.student.id, startDate, true).then(r => {
                                this.student.availableTime.secondsLeftSimulator -= r.data.seconds;
                            });
                        }
                    }
                    else {
                        if (this.student.availableTime != null && this.student.availableTime.secondsLeftPractice > 0) {
                            BookingDataService.getCountSecondsByStudentAndDate(this.student.id, startDate, false).then(r => {
                                this.student.availableTime.secondsLeftPractice -= r.data.seconds;
                            });
                        }
                    }
                })
                .catch(e => {
                    console.log(e);
                });
        } else {
            this.getStudents();
        }

        if (this.booking.instructorId > 0) {
            InstructorDataService.getAll()
                .then(response => {
                    this.instructors = response.data;
                })
                .catch(e => {
                    console.log(e);
                });
        } else {
            InstructorAvailabilityDataService.getIncludeByDate(this.booking.dateStart, this.booking.dateEnd)
                .then(response_availability => {
                    BookingDataService.getIntersectByDate(this.booking.dateStart, this.booking.dateEnd).then(response_bookings => {
                        let bookings = response_bookings.data;
                        let availabilities = response_availability.data;
                        for (let availability of availabilities) {
                            let books_tmp = bookings.filter(book => book.instructor.id == availability.instructor.id);
                            if (books_tmp.length == 0) {
                                this.instructors.push(availability.instructor);
                            }
                        }
                    })
                        .catch(e => {
                            console.log(e);
                        });
                })
                .catch(e => {
                    console.log(e);
                });
        }

        if (this.booking.vehicleId) {
            this.canChangeVehicle = false;
        }


        VehicleDataService.getAll()
            .then(response => {
                if (this.isVirtual) this.vehicles = response.data.filter(v => v.isVirtual);
                else this.vehicles = response.data.filter(v => !v.isVirtual);
            })
            .catch(e => {
                console.log(e);
            });


        let x = new moment(this.booking.dateStart);
        let y = new moment(this.booking.dateEnd);
        this.difference_date_booking = moment.duration(y.diff(x)).asSeconds();
    }
};
</script>

<style>
.submit-form {
    max-width: 300px;
    margin: auto;
}
</style>
